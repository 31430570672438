import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import fetchData from "../schedule/scripts/fetchData.js";

export default function ZoomSubAccountDetails({ accountId }) {
  const [selectedAccount, setSelectedAccount] = useState([]);

  useEffect(() => {
    getZoomAccountDetails();
  }, [accountId]);

  const getZoomAccountDetails = async () => {
    const url = `${process.env.REACT_APP_ROOT_URL}/zoom/accounts/${accountId}`;
    try {
      const response = await fetchData(url, "GET");
      setSelectedAccount(response);
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  };

  return (
    <>
      <table className="table">
        <tbody>
          <tr>
            <td className="zoom-header-cell">Account name:</td>
            <td className="zoom-info-cell">{selectedAccount.account_name}</td>
            <td className="zoom-header-cell">Owner email:</td>
            <td className="zoom-info-cell">{selectedAccount.owner_email}</td>
            <td className="zoom-header-cell">Created:</td>
            <td className="zoom-info-cell">
              {dayjs(selectedAccount.created_at).format("DD/MM/YYYY")}
            </td>
          </tr>
          <tr>
            <td className="zoom-header-cell">Account ID:</td>
            <td className="zoom-info-cell">{selectedAccount.id}</td>
            <td className="zoom-header-cell">Account type:</td>
            <td className="zoom-info-cell">{selectedAccount.account_type}</td>
            <td className="zoom-header-cell">Subscription starts:</td>
            <td className="zoom-info-cell">
              {dayjs(selectedAccount.subscription_start_time).format(
                "DD/MM/YYYY"
              )}
            </td>
          </tr>
          <tr>
            <td className="zoom-header-cell">Account number:</td>
            <td className="zoom-info-cell">{selectedAccount.account_number}</td>
            <td className="zoom-header-cell">Seats:</td>
            <td className="zoom-info-cell">{selectedAccount.seats}</td>
            <td className="zoom-header-cell">Subscription ends:</td>
            <td className="zoom-info-cell">
              {dayjs(selectedAccount.subscription_end_time).format(
                "DD/MM/YYYY"
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
