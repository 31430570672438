import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import fetchData from "../schedule/scripts/fetchData.js";

export default function ZoomSubAccountUserMeetings({ accountId, userId }) {
  const [userMeetings, setUserMeetings] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [pastMeetings, setPastMeetings] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);

  const getMeetings = async () => {
    const url = `${process.env.REACT_APP_ROOT_URL}/zoom/accounts/${accountId}/users/${userId}/meetings`;
    try {
      const response = await fetchData(url, "GET");
      if (response) {
        const meetings = response.meetings
          ?.filter((meeting) =>
            dayjs(meeting.start_time)
              .add(meeting.duration, "minute")
              .isAfter(dayjs(new Date()))
          )
          .sort((a, b) => dayjs(a.start_time) - dayjs(b.start_time));
        setUpcomingMeetings(meetings);
        setUserMeetings(meetings);
        setPastMeetings(
          response.meetings
            ?.filter((meeting) =>
              dayjs(meeting.start_time)
                .add(meeting.duration, "minute")
                .isBefore(dayjs(new Date()))
            )
            .sort((a, b) => dayjs(b.start_time) - dayjs(a.start_time))
        );
      }
    } catch (error) {
      console.error("Error fetching user meetings:", error);
    }
  };

  useEffect(() => {
    getMeetings();
  }, [userId]);

  useEffect(() => {
    if (selectedTab === 1) {
      setUserMeetings(upcomingMeetings);
    } else {
      setUserMeetings(pastMeetings);
    }
  }, [userId, selectedTab]);

  return (
    <div className="zoom-meetings">
      <div className="meetings-tab-div">
        <div
          className="meetings-tab left-tab"
          onClick={() => setSelectedTab(1)}
        >
          Upcoming meetings ({upcomingMeetings?.length || 0})
        </div>
        <div>
          <div
            className="meetings-tab right-tab"
            onClick={() => setSelectedTab(0)}
          >
            Past meetings ({pastMeetings?.length || 0})
          </div>
        </div>
      </div>
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <td>Start time</td>
            <td>Duration</td>
            <td>Time zone</td>
            <td>Topic</td>
            <td>ID</td>
            <td>Created</td>
            {selectedTab === 1 && <td>Join</td>}
          </tr>
        </thead>
        <tbody>
          {userMeetings?.map((meeting) => {
            return (
              <tr key={meeting.id}>
                <td className="centered-column">
                  {dayjs(meeting.start_time).format("DD/MM/YYYY HH:mm:ss")}
                </td>
                <td className="numeric-column">{meeting.duration}</td>
                <td>{meeting.timezone}</td>
                <td>{meeting.topic}</td>
                <td className="centered-column">{meeting.id}</td>
                <td className="centered-column">
                  {dayjs(meeting.created_at).format("DD/MM/YYYY HH:mm:ss")}
                </td>
                {selectedTab === 1 && (
                  <td className="centered-column">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://us02web.zoom.us/j/${meeting.id}`}
                    >
                      Join link
                    </a>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
