import React, { useState, useEffect } from "react";
import ZoomSubAccountUser from "./ZoomSubAccountUser.js";
import NewModal from "../schedule/NewModal.js";
import dayjs from "dayjs";
import fetchData from "../schedule/scripts/fetchData.js";

export default function SubAccountUsers({ accountId }) {
  const [accountUsers, setAccountUsers] = useState([]);
  const [accountUserCount, setAccountUserCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState();
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const [licensedOnly, setLicensedOnly] = useState(false);
  const [sortColumn, setSortColumn] = useState("last_name");
  const [ascSortOrder, setAscSortOrder] = useState(true);
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    getZoomSubaccountUsers();
  }, [accountId]);

  useEffect(() => {
    setAscSortOrder(true);
  }, [sortColumn]);

  const getZoomSubaccountUsers = async () => {
    const url = `${process.env.REACT_APP_ROOT_URL}/zoom/accounts/${accountId}/users`;
    try {
      const response = await fetchData(url, "GET");
      if (response) {
        let sortedUsers = response.users?.sort((a, b) => {
          return a.last_name.localeCompare(b.last_name);
        });
        setAccountUsers(sortedUsers);
        sessionStorage.setItem("accountUsers", JSON.stringify(sortedUsers));
        setAccountUserCount(response.users.length);
      }
    } catch (error) {
      console.error("Error fetching subaccount users:", error);
    }
  };

  function showZoomUser(userId) {
    setSelectedUser(userId);
    setNewModalContent(
      <ZoomSubAccountUser
        accountId={accountId}
        userId={userId}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  }

  const filterUsers = () => {
    let users = JSON.parse(sessionStorage.getItem("accountUsers"));
    setAccountUsers(
      users?.filter((user) => {
        return (
          user.first_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          user.last_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          user.id?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          (user.pmi && user.pmi.toString().includes(searchTerm)) ||
          user.owner_email?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
      })
    );
  };

  useEffect(() => {
    filterUsers();
  }, [searchTerm]);

  const resetSortOrder = (column) => {
    setSortColumn(column);
    setAscSortOrder(true);
    return true;
  };

  const reverseSortOrder = () => {
    setAscSortOrder(!ascSortOrder);
    return !ascSortOrder;
  };

  const filterColumn = (column, type) => {
    let thisSort = ascSortOrder;
    column !== sortColumn
      ? (thisSort = resetSortOrder(column))
      : (thisSort = reverseSortOrder());
    const sortedAccountUsers = [...accountUsers].sort((a, b) => {
      let comparison = 0;
      if (type === "text") {
        comparison = a[column].localeCompare(b[column]);
      } else if (type === "number") {
        comparison = a[column] - b[column];
      } else if (type === "date") {
        comparison = dayjs(a[column]) - dayjs(b[column]);
      }
      return thisSort ? comparison : -comparison;
    });
    setAccountUsers(sortedAccountUsers);
  };

  const displaySortIcon = ascSortOrder ? (
    <i className=" fa fa-solid fa-sort-up sort-icon" />
  ) : (
    <i className=" fa fa-solid fa-sort-down sort-icon" />
  );

  const accountUsersDisplay = accountUsers?.map((user) => {
    return (
      <tr
        key={user.id}
        className={`${user?.id === selectedUser ? "selected-user" : ""} ${
          user.type === 1 && licensedOnly ? "hide-basic" : ""
        }`}
      >
        <td>
          <span className="link" onClick={() => showZoomUser(user.id)}>
            {user.id}
          </span>
        </td>
        <td>{user.last_name}</td>
        <td>{user.first_name}</td>
        <td>{user.email}</td>
        <td className="numeric-column">{user.pmi}</td>
        <td>{user.timezone}</td>
        <td>{user.type === 1 ? "Basic" : "Licensed"}</td>
        <td>{user.status}</td>
        <td className="centered-column">
          {dayjs(user.last_login_time).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="centered-column">
          {dayjs(user.created_at).format("DD/MM/YYYY HH:mm")}
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="zoom-info-div">
        <div className="zoom-info-left-div">
          <h3>Account users ({accountUserCount})</h3>
        </div>
        <div className="zoom-info-right-div">
          <label htmlFor="licensedOnly" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="licensedOnly"
              className="filter-checkbox"
              checked={licensedOnly}
              onChange={() => setLicensedOnly(!licensedOnly)}
            />
            Show licensed users only
          </label>
          <input
            type="text"
            placeholder="Filter by name, email, PMI, ID ..."
            id="searchTerm"
            name="searchTerm"
            className="zoom-search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            name="searchBtn"
            disabled={!searchTerm}
            onClick={() => setSearchTerm("")}
            className="schedule-form-button"
          >
            Reset
          </button>
        </div>
      </div>
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <td>ID</td>
            <td>
              <span
                className="column-filter"
                onClick={() => filterColumn("last_name", "text")}
              >
                Last name
                {sortColumn === "last_name" && displaySortIcon}
              </span>
            </td>
            <td>
              <span
                className="column-filter"
                onClick={() => filterColumn("first_name", "text")}
              >
                First name
                {sortColumn === "first_name" && displaySortIcon}
              </span>
            </td>
            <td>
              <span
                className="column-filter"
                onClick={() => filterColumn("email", "text")}
              >
                Email
                {sortColumn === "email" && displaySortIcon}
              </span>
            </td>
            <td>
              <span
                className="column-filter"
                onClick={() => filterColumn("pmi", "number")}
              >
                PMI
                {sortColumn === "pmi" && displaySortIcon}
              </span>
            </td>
            <td>Time zone</td>
            <td>
              <span
                className="column-filter"
                onClick={() => filterColumn("type", "number")}
              >
                Type
                {sortColumn === "type" && displaySortIcon}
              </span>
            </td>
            <td>Status</td>
            <td>
              <span
                className="column-filter"
                onClick={() => filterColumn("last_login_time", "date")}
              >
                Last login
                {sortColumn === "last_login_time" && displaySortIcon}
              </span>
            </td>
            <td>
              {" "}
              <span
                className="column-filter"
                onClick={() => filterColumn("created_at", "date")}
              >
                Created
                {sortColumn === "created_at" && displaySortIcon}
              </span>
            </td>
          </tr>
        </thead>
        <tbody>{accountUsersDisplay}</tbody>
      </table>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}
