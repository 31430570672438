import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import fetchData from "../schedule/scripts/fetchData.js";

export default function ZoomSubAccounts() {
  const [subAccounts, setSubAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const getSubAccounts = async () => {
    let accounts = JSON.parse(sessionStorage.getItem("subAccounts"));
    if (accounts) {
      setSubAccounts(accounts);
      return;
    }
    const url = `${process.env.REACT_APP_ROOT_URL}/zoom/accounts`;
    try {
      const response = await fetchData(url, "GET");
      setSubAccounts(response.accounts);
      sessionStorage.setItem("subAccounts", JSON.stringify(response.accounts));
    } catch (error) {
      console.error("Error fetching subaccounts:", error);
    }
  };

  useEffect(() => {
    getSubAccounts();
  }, []);

  function goToSubAccount(account_id) {
    navigate(`/zoom-account/${account_id}`);
  }

  const filterAccounts = () => {
    let accounts = JSON.parse(sessionStorage.getItem("subAccounts"));
    setSubAccounts(
      accounts?.filter((account) => {
        return (
          account.account_name
            ?.toLowerCase()
            .includes(searchTerm?.toLowerCase()) ||
          account.owner_email?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
      })
    );
  };

  useEffect(() => {
    filterAccounts();
  }, [searchTerm]);

  return (
    <>
      <div className="zoom-info-div">
        <div className="zoom-info-left-div">
          <h2>Zoom subadmin accounts</h2>
        </div>
        <div className="zoom-info-right-div">
          <input
            type="text"
            placeholder="Filter by account name, ID, owner ..."
            id="searchTerm"
            name="searchTerm"
            className="zoom-search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            name="searchBtn"
            disabled={!searchTerm}
            onClick={() => setSearchTerm("")}
            className="schedule-form-button"
          >
            Reset
          </button>
        </div>
      </div>
      <div className="schedule-container-div">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <td>Account name</td>
              <td>Account ID</td>
              <td>Account number</td>
              <td>Owner</td>
              <td>Seats</td>
              <td>Created</td>
              <td>Subscr. ends</td>
            </tr>
          </thead>
          <tbody>
            {subAccounts ? (
              subAccounts.map((account) => (
                <tr
                  key={account.id}
                  className={
                    !account.subscription_end_time
                      ? "inactive-account"
                      : undefined
                  }
                >
                  <td>{account.account_name}</td>
                  <td>
                    <span
                      className="link"
                      onClick={() => goToSubAccount(account.id)}
                    >
                      {account.id}
                    </span>
                  </td>
                  <td className="numeric-column">{account.account_number}</td>
                  <td>{account.owner_email}</td>
                  <td className="numeric-column">{account.seats}</td>
                  <td className="centered-column">
                    {dayjs(account.created_at).format("DD/MM/YYYY")}
                  </td>
                  <td className="centered-column">
                    {account.subscription_end_time &&
                      dayjs(account.subscription_end_time).format("DD/MM/YYYY")}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="loadingCell" colSpan="9">
                  Loading ....
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
