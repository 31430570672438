import ZoomSubAccountUsers from "./ZoomSubAccountUsers";
import ZoomSubAccountDetails from "./ZoomSubAccountDetails";
import { useParams } from "react-router-dom";

export default function SubAccount() {
  const { accountId } = useParams();

  return (
    <div>
      <div className="table-header-div">
        <div className="table-title-div">
          <h2>Zoom subadmin account</h2>
        </div>
      </div>
      <ZoomSubAccountDetails accountId={accountId} />
      <ZoomSubAccountUsers accountId={accountId} />
    </div>
  );
}
