import PublishErrorList from "./PublishErrorList";
import ValidationErrorList from "./ValidationErrorList";
import PublishConfirmation from "./PublishConfirmation";
import PublishSuccess from "./PublishSuccess";
import PublishCheckSuccess from "./PublishCheckSuccess";
import Spinner from "./Spinner";
import { useUserSettings } from "../../auth/useUserSettings";
import dayjs from "dayjs";
import fetchData from "./scripts/fetchDataNew.js";

export default function PublishSchedule({
  scheduleId,
  centre,
  getExportList,
  reloadPublishedSchedule,
  setShowNewModal,
  setNewModalContent,
  setClashClassList,
  showErrorModal,
}) {
  const [userSettings] = useUserSettings();
  const scheduleToPost = {
    schedule_id: scheduleId,
    centre_id: centre.value,
    createby: userSettings.admin_id,
    mode: 0,
    class_list: [],
  };
  const isVirtual = centre?.is_virtual;

  function createErrorList(list) {
    let newList = list.map((item) => {
      return { dateStr: null, id: item.id, type: null };
    });
    setClashClassList(newList);
    return newList;
  }

  const confirmPublishClasses = () => {
    if (scheduleToPost.mode === 1) {
      const classCount = scheduleToPost.class_list.length;
      setNewModalContent(
        <PublishConfirmation
          scheduleToPost={scheduleToPost}
          classCount={classCount}
          startPublishing={postSchedule}
          setShowNewModal={setShowNewModal}
        />
      );
      setShowNewModal(true);
    } else {
      postSchedule();
    }
  };

  const showSpinner = (mode, classCount) => {
    setNewModalContent(
      <Spinner
        message={
          mode === 0
            ? `Checking ${classCount} class${
                classCount > 1 ? "es" : ""
              } for errors or clashes, please wait...`
            : `Publishing ${classCount} class${
                classCount > 1 ? "es" : ""
              }, please wait a moment ...`
        }
        timer={true}
      />
    );
    setShowNewModal(true);
  };

  const showPublishCheckSuccess = () => {
    setNewModalContent(
      <PublishCheckSuccess
        setShowNewModal={setShowNewModal}
        scheduleToCheck={scheduleToPost}
      />
    );
    setShowNewModal(true);
  };

  const postSchedule = async () => {
    let mode = scheduleToPost.mode;
    let classCount = scheduleToPost.class_list.length;
    showSpinner(mode, classCount);
    console.log(scheduleToPost);
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/publish-schedule/${centre?.centre_id}`;
    let response = await fetchData(url, "POST", scheduleToPost);
    console.log(response);
    if (response.result === true) {
      let data = response.data;
      if (scheduleToPost.mode === 0) {
        checkSchedule(data);
      } else if (scheduleToPost.mode === 1) {
        publishSchedule(data);
      }
    } else {
      showErrorModal(response.message);
    }
  };

  const checkSchedule = (data) => {
    if (data.result === true) {
      showPublishCheckSuccess();
    } else if (data?.result === false) {
      showErrors(data);
    }
  };

  const publishSchedule = async (data) => {
    if (data.result === false) {
      showErrors(data);
    } else if (data.result === true) {
      let jobId = data.message.id;
      checkPostScheduleStatus(jobId);
    }
  };

  const checkPostScheduleStatus = async (jobId) => {
    let jobStatus = false;
    let attempts = 1;
    const maxRetries = process.env.REACT_APP_PUBLISH_CHECK_LIMIT || 200;
    let checkResponse;
    try {
      do {
        await new Promise((resolve) =>
          setTimeout(resolve, process.env.REACT_APP_PUBLISH_CHECK_RATE || 3000)
        );
        const url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/check-schedule-job-status/${centre?.centre_id}/${jobId}`;
        checkResponse = await fetchData(url, "GET");
        if (checkResponse.data.job_status === true) {
          jobStatus = true;
        } else {
          attempts++;
        }
      } while (!jobStatus && attempts < maxRetries);

      if (jobStatus) {
        reloadPublishedSchedule(scheduleToPost.schedule_id);
        setNewModalContent(
          <PublishSuccess setShowNewModal={setShowNewModal} />
        );
      } else if (attempts === maxRetries) {
        showErrorModal("Error: publish process timed out.");
      }
    } catch (error) {
      console.error("Error checking schedule job status:", error);
      showErrorModal(`Error: " ${error.message}`);
    }
  };

  const showErrors = (response) => {
    createErrorList(response.message.class_list);
    setNewModalContent(
      <PublishErrorList
        errorList={response.message}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  };

  function validatePublishSchedule(publishClassList) {
    let incompleteClassCount = 0;
    let pastClassCount = 0;
    let publishedClassCount = 0;
    const validationList = [
      "centre",
      "lesson",
      "dateStr",
      "duration",
      "teacher",
      "classroom",
      "capacity",
      "delivery",
      "credit",
      "levels",
      "products",
    ];
    const virtualValidationList = [
      "centres",
      "lesson",
      "dateStr",
      "duration",
      "teacher",
      "capacity",
      "delivery",
      "credit",
      "levels",
      "products",
    ];
    for (const classInstance of publishClassList) {
      classInstance["class_id"] && publishedClassCount++;
    }
    for (const classInstance of publishClassList) {
      let emptyCellCount = 0;
      for (const item of isVirtual ? virtualValidationList : validationList) {
        classInstance[item] === "" && emptyCellCount++;
      }
      emptyCellCount > 0 && incompleteClassCount++;
    }
    for (const classInstance of publishClassList) {
      classInstance["dateStr"] < dayjs(new Date()).format() && pastClassCount++;
    }
    return {
      a: incompleteClassCount,
      b: pastClassCount,
      c: publishedClassCount,
    };
  }

  function getPublishSchedule(mode) {
    let publishClassList = getExportList();
    let errors = validatePublishSchedule(publishClassList);
    if (errors.a === 0 && errors.b === 0 && errors.c === 0) {
      let classArray = [];
      if (publishClassList.length > 0) {
        for (const item of publishClassList) {
          let newItem = {};
          newItem["id"] = item.temp_id;
          newItem[item.lesson.type] = item.lesson?.value;
          newItem["lesson_time"] = dayjs
            .utc(item.dateStr)
            .format("YYYY-MM-DD HH:mm:ss");
          newItem["teacher"] = Number(item.teacher?.value);
          newItem["classroom"] = item.classroom?.value;
          newItem["duration"] = Number(item.duration?.value);
          newItem["capacity"] = Number(item.capacity?.value);
          newItem["delivery"] = isVirtual ? 2 : 1;
          newItem["credit"] = Number(item.credit?.value);
          newItem["levels"] =
            item.levels.length > 0 &&
            item.levels.map((level) => {
              return Number(level.value);
            });
          newItem["products"] =
            item.products.length > 0 &&
            item.products.map((product) => {
              return product.value;
            });
          if (isVirtual) {
            newItem["centres"] =
              item.virtualCentres.length > 0 &&
              item.virtualCentres.map((virtualCentre) => {
                return virtualCentre.value;
              });
          }
          newItem["closed"] = Number(item.isClosed?.value);
          if (item.module) {
            newItem["module_code"] =
              item.module?.value.split(":")[0] +
              ":" +
              item.module?.value.split(":")[1] +
              ":" +
              item.module?.value.split(":")[2] +
              ":" +
              1;
          }
          if (item.lesson.session_name) {
            newItem["session_name"] = item.lesson.session_name;
          }
          if (item.lesson.session_description) {
            newItem["session_description"] = item.lesson.session_description;
          }
          classArray.push(newItem);
        }
        scheduleToPost["class_list"] = classArray;
        scheduleToPost["mode"] = mode;
        confirmPublishClasses();
      }
    } else {
      setNewModalContent(
        <ValidationErrorList
          errors={errors}
          setShowNewModal={setShowNewModal}
        />
      );
      setShowNewModal(true);
    }
  }

  return (
    <>
      <button
        className="schedule-form-button"
        onClick={() => getPublishSchedule(0)}
      >
        Check
      </button>
      <button
        className="schedule-form-button"
        onClick={() => getPublishSchedule(1)}
      >
        Publish
      </button>
    </>
  );
}
