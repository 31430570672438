import ModuleCheck from "./ModuleCheck";

export default function PublishCheck({ scheduleToCheck, setShowNewModal }) {
  return (
    <div>
      <ModuleCheck scheduleToCheck={scheduleToCheck} />
      <p>OBS check: No errors or clashes found.</p>
      <div className="modal-button-div">
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
}
