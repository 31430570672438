import { useEffect, useState } from "react";
import NewModalCloseButton from "./NewModalCloseButton";
import queryString from "query-string";
import dayjs from "dayjs";
import { useUserSettings } from "../../auth/useUserSettings";
import obsClassLink from "./scripts/obsClassLink.js";
import fetchData from "./scripts/fetchData.js";

export default function LessonHistory({
  centre,
  lessonId,
  lessonTitle,
  classType,
  teacherName,
  setShowNewModal,
}) {
  const [lessonHistory, setLessonHistory] = useState([]);
  const [userSettings] = useUserSettings();
  const [reqObj, setReqObj] = useState({
    country_id: centre?.country_id,
    id: [],
    type: classType,
    from_date: dayjs(userSettings.user_settings.history_since.toString())
      .startOf("year")
      .format("YYYY-MM-DD HH:mm:ss"),
  });

  function openObsClassLink(class_id) {
    let region = class_id.length === 12 ? "eu" : "ea";
    obsClassLink(class_id, region);
  }

  const getLessonHistory = async () => {
    if (centre) {
      const countryId = centre?.country_id;
      const centreId = centre?.centre_id;
      const user_option = userSettings.user_settings.teacher_search;
      reqObj["id"].push(lessonId);
      let url = `${
        process.env.REACT_APP_ROOT_URL
      }/schedule/obs/${countryId}/history?${queryString.stringify(reqObj, {
        arrayFormat: "bracket",
      })}`;
      const results = await fetchData(url, "GET");
      if (results.data) {
        let filteredResults = results.data;
        if (user_option === 0) {
          filteredResults = results.data.filter(
            (item) => item.centre_id === centreId
          );
        }
        const sortedResults = [...filteredResults].sort((a, b) =>
          a.class_date > b.class_date ? -1 : 1
        );
        setLessonHistory(sortedResults);
      }
    }
  };

  const history =
    lessonHistory.length > 0 ? (
      lessonHistory?.map((item) => {
        return (
          <tr
            key={item.class_id}
            className={
              teacherName && teacherName === item.teacher && "selected-schedule"
            }
          >
            <td>
              <span
                className="obs-link"
                title="Open CMS2 class page"
                onClick={() => openObsClassLink(item.class_id, centre?.region)}
              >
                {item.class_id}
              </span>
            </td>
            <td>{dayjs.unix(item.gmt).format("DD/MM/YYYY")}</td>
            <td>{dayjs.unix(item.gmt).format("HH:mm")}</td>
            <td>{dayjs(item.class_date).format("ddd")}</td>
            <td className="text-column">{item.teacher}</td>
            <td className="numeric-column">{item.capacity}</td>
            <td className="numeric-column">{item.reg}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={7}>
          {/* No instances of this lesson since [from date] */}
        </td>
      </tr>
    );

  useEffect(() => {
    getLessonHistory();
  }, [lessonId]);

  return (
    <>
      <div className="text-column">
        <h3>Lesson history - {lessonTitle}</h3>
      </div>
      <table>
        <thead className="lesson-history-table-header">
          <tr>
            <td>Class ID</td>
            <td>Day</td>
            <td>Date</td>
            <td>Time</td>
            <td>Teacher</td>
            <td>Max</td>
            <td>Reg.</td>
          </tr>
        </thead>
        <tbody className="lesson-history-table-body">{history}</tbody>
      </table>
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </>
  );
}
