import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function ObjectTests() {
  const [output, setOutput] = useState([]);

  function addTimeSlot(date, minutes) {
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  }
  function checkTeacherClash(
    range1start,
    range1end,
    range2start,
    range2end,
    teacher_id
  ) {
    if (range1start < range2end && range2start < range1end) {
      return true;
    }
    return false;
  }

  const today = dayjs(new Date()).format("YYYY,MM,DD");
  let startDate = new Date(today);
  let startDateCopy = startDate;
  let endDate = new Date(2024, 0, 10);
  let time = 540;
  let duration = 90;
  let loopStartDate = addTimeSlot(startDateCopy, time);
  let loopEndDate = addTimeSlot(endDate, time);
  for (let i = loopStartDate; i <= loopEndDate; i.setDate(i.getDate() + 1)) {
    let classObj = {};
    classObj["classDate"] = dayjs(i).format();
    output.push(classObj);
  }
  console.log(output);
  const dateArray = output.map((day) => {
    return <p>{dayjs(day.classDate).format("YYYY-MM-DD HH:mm:ss")}</p>;
  });
  return (
    <>
      <h1>Objects tests</h1>
      {dateArray}
      <br />
      {dayjs(startDate).valueOf()}
      <br />
      {dayjs(endDate).format()}
      <br />
      {time}
      <br />
      {dayjs(loopStartDate).format()}
    </>
  );
}
