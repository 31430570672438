import { useEffect, useState } from "react";
import getLessonsFromCacheByTheme from "../schedule/scripts/getLessonsFromCacheByTheme.js";

export default function ModuleCheck({ scheduleToCheck }) {
  const [moduleIntegrityStatus, setModuleIntegrityStatus] = useState(0);
  const [moduleCount, setModuleCount] = useState(0);
  const [moduleIntegrityCheckList, setModuleIntegrityCheckList] = useState([]);

  function checkModuleIntegrity() {
    setModuleIntegrityCheckList([]);
    const moduleArray = scheduleToCheck.class_list.reduce((acc, curr) => {
      if (!curr.module_code) return acc;
      const moduleCode = curr.module_code.split(":")[0];
      const themeId = curr.module_code.split(":")[1];
      const lessonId = curr.lesson;
      const moduleIndex = acc.findIndex(
        (module) => module.module_code === moduleCode
      );
      if (moduleIndex === -1) {
        acc.push({
          module_code: moduleCode,
          theme_id: themeId,
          lessons: [lessonId],
        });
      } else {
        acc[moduleIndex].lessons.push(lessonId);
      }
      return acc;
    }, []);
    setModuleCount(moduleArray.length);
    if (moduleArray.length === 0) {
      setModuleIntegrityStatus(0);
      return;
    }
    setModuleIntegrityStatus(1);
    moduleArray.forEach((module) => {
      const themeLessons = getLessonsFromCacheByTheme(
        null,
        Number(module.theme_id)
      );
      const missingLessons = themeLessons.filter(
        (lesson) => !module.lessons.includes(lesson.lesson_id)
      );
      const duplicateLessons = themeLessons.filter((lesson) =>
        module.lessons
          .filter((l, index, self) => self.indexOf(l) !== index)
          .includes(lesson.lesson_id)
      );
      if (missingLessons.length > 0 || duplicateLessons.length > 0) {
        let defectiveModule = {
          module_code: module.module_code,
          missingLessons: missingLessons,
          duplicateLessons: duplicateLessons,
        };
        setModuleIntegrityCheckList((prev) => [...prev, defectiveModule]);
      }
    });
  }

  useEffect(() => {
    checkModuleIntegrity();
  }, [scheduleToCheck]);

  const showModuleIntegrityCheckList = moduleIntegrityCheckList.map(
    (module, index) => (
      <div key={index}>
        {module.missingLessons.length > 0 && (
          <>
            <p>
              Module <b>{module.module_code}</b> is missing{" "}
              {module.missingLessons.length} lesson
              {module.missingLessons.length > 1 ? "s" : ""}:
            </p>
            {module.missingLessons.map((lesson) => (
              <div className="module-check-item" key={lesson.lesson_id}>
                {lesson.lesson_code + ": " + lesson.lesson_title}
              </div>
            ))}
          </>
        )}
        {module.duplicateLessons.length > 0 && (
          <>
            <p>
              Module <b>{module.module_code}</b> has{" "}
              {module.duplicateLessons.length} duplicate lesson
              {module.duplicateLessons.length > 1 ? "s" : ""}:
            </p>
            {module.duplicateLessons.map((lesson) => (
              <div className="module-check-item" key={lesson.lesson_id}>
                {lesson.lesson_code + ": " + lesson.lesson_title}
              </div>
            ))}
          </>
        )}
      </div>
    )
  );

  return (
    <div className="module-check-div">
      <>
        <h3>Module check</h3>
        {moduleIntegrityStatus === 1 ? (
          <>
            <p>
              {moduleCount} module{moduleCount > 1 && "s"}{" "}
              {moduleCount > 1 ? "were" : "was"} checked:
            </p>
            {moduleIntegrityCheckList.length > 0 ? (
              <div>{showModuleIntegrityCheckList}</div>
            ) : (
              <div>No issues found.</div>
            )}
          </>
        ) : (
          <div>No modules found.</div>
        )}
      </>
    </div>
  );
}
